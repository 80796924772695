/**
 * Created by jerry on 2020/03/30.
 * 产品销售提佣 api
 */
import * as API from '@/api/index'

export default {
    // 获取产品销售组织单位提成方案列表
    getProductCommissionScheme: params => {
        return API.POST('api/saleProductCommissionScheme/list', params)
    },
    // 创建产品销售组织单位提成方案
    createProductCommissionScheme: params => {
        return API.POST('api/saleProductCommissionScheme/create', params)
    },
    // 删除产品销售提成方案
    deleteProductCommissionScheme: params => {
        return API.POST('api/saleProductCommissionScheme/delete', params)
    },
    // 获取产品销售分类提佣方案
    getProductCategoryCommission: params => {
        return API.POST('api/saleProductCategoryCommission/all', params)
    },
    // 保存产品销售分类提成方案
    updateProductCategoryCommission: params => {
        return API.POST('api/saleProductCategoryCommission/update', params)
    },
    // 获取产品销售分类经手人提佣方案
    getProductCategoryHandlerCommission: params => {
        return API.POST('api/saleProductCategoryHandlerCommission/all', params)
    },
    //保存产品销售分类经手人提成方案
    updateProductCategoryHandlerCommission: params => {
        return API.POST('api/saleProductCategoryHandlerCommission/update', params)
    },
    //获取分类下的产品提佣方案
    getProductCommission: params => {
        return API.POST('api/saleProductCommission/all', params)
    },
    //保存分类下的产品销售提成方案
    updateProductCommission: params => {
        return API.POST('api/saleProductCommission/update', params)
    },
    //获取产品下经手人提佣方案
    getProductHanderCommission: params => {
        return API.POST('api/saleProductHandlerCommission/all', params)
    },
    //保存产品下经手人提成方案
    updateProductHanderCommission: params => {
        return API.POST('api/saleProductHandlerCommission/update', params)
    },
    //获取所有产品经手人提佣方案
    getProductSchemeHanderCommission: params => {
        return API.POST('api/saleProductSchemeHandlerCommission/all', params)
    },
    //保存所有产品经手人提成方案
    updateProductSchemeHanderCommission: params => {
        return API.POST('api/saleProductSchemeHandlerCommission/update', params)
    },
    //31.14.获取所有套餐卡产品经手人提佣方案
    getPackageCardProductSchemeHanderCommission: params => {
        return API.POST('api/saleProductSchemeHandlerCommission/packageCard', params)
    },
    //31.8.获取套餐卡产品销售分类提佣方案
    getPackageCardSaleProductCategoryHandlerCommission: params => {
        return API.POST('api/saleProductCategoryHandlerCommission/packageCard', params)
    },
    //31.13.获取套餐卡产品经手人提佣方案
    getPackageCardSaleProductHandlerCommission: params => {
        return API.POST('api/saleProductHandlerCommission/packageCard', params)
    },
    
  }